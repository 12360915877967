import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import usePaymentBlock from 'hooks/payment/usePaymentBlock';
import useNotificationsQuery from './user-notifications/useNotificationsQuery';
import convertTimestampToHumanDate from 'utils/date/convertTimestampToHumanDate';

import { MessageType } from '../constants/types';

const useUserNotifications = (options = {}) => {
  const { disabled, refetchOnMount = false } = options;
  const { user } = useSelector(state => state.user);

  const {
    loading,
    data: userNotifications,
    refetch: getUserNotifications,
    onChange: setUserNotifications,
  } = useNotificationsQuery({ refetchOnMount });

  const paymentBlockNotification = useMemo(() => {
    return userNotifications.find(item => item.msg === MessageType.PAYMENT_BLOCK);
  }, [userNotifications]);

  const onPaymentsBlock = useCallback(() => {
    if (disabled) {
      return;
    }

    if (paymentBlockNotification) {
      return;
    }

    if (user) {
      getUserNotifications();
    }
  }, [disabled, paymentBlockNotification, user, getUserNotifications]);

  const { block, setBlock } = usePaymentBlock({ onBlock: onPaymentsBlock });

  useEffect(() => {
    if (disabled) {
      return;
    }

    if (user) {
      getUserNotifications();
    } else {
      setUserNotifications([]);
    }
  }, [setUserNotifications, getUserNotifications, user, disabled]);

  useEffect(() => {
    if (paymentBlockNotification && !block) {
      const unlockTimestamp = paymentBlockNotification?.options?.unlockDate;
      const unlockDate = convertTimestampToHumanDate(unlockTimestamp);

      setBlock({ block: true, unlockDate });
    }
  }, [paymentBlockNotification, block, setBlock]);

  return { userNotifications, loading };
};

export default useUserNotifications;
