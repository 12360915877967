import React from 'react';
import { Result } from 'antd';
import { useTranslation } from 'react-i18next';

const WatcherAutorizeFailResult = () => {
  const { t } = useTranslation();

  return (
    <Result
      status="error"
      title={t('watcher.errors.protectedWatcherLinkPath.title')}
      subTitle={t('watcher.errors.protectedWatcherLinkPath.subTitle')}
    />
  );
};

export default WatcherAutorizeFailResult;
