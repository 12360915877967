import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { BaseModal } from 'components';

import './ConfirmModal.styles.scss';

const ConfirmModal = ({
  open,
  loading,
  cancelLoading,
  agreeButtonText = 'form.button.confirm',
  cancelButtonText = 'form.button.cancel',
  agreeButtonProps,
  cancelButtonProps,
  children,
  onClose,
  onCancel,
  onAgree,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <BaseModal open={open} onClose={onClose} className="np-confirm-modal" {...props}>
      <>
        {children}

        <div className="np-confirm-modal__buttons">
          <Button
            block
            type="default"
            size="large"
            loading={cancelLoading}
            disabled={loading || cancelLoading}
            onClick={onCancel || onClose}
            {...cancelButtonProps}
          >
            {t(cancelButtonText)}
          </Button>

          <Button
            block
            type="primary"
            size="large"
            loading={loading}
            disabled={loading || cancelLoading}
            onClick={onAgree}
            {...agreeButtonProps}
          >
            {t(agreeButtonText)}
          </Button>
        </div>
      </>
    </BaseModal>
  );
};

export default ConfirmModal;
