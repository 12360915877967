import React from 'react';

import { TableWithHeader } from 'components';

import useMiningServersTableColumns from './hooks/useSignInHistoryTableColumns';

import './SignInHistoryTable.styles.scss';

const SignInHistoryTable = ({ ...props }) => {
  const columns = useMiningServersTableColumns();

  return (
    <TableWithHeader
      title="accountSettings.signInHistory.title"
      description="accountSettings.signInHistory.disclaimer"
      columns={columns}
      rowKey={record => record.date}
      scroll={{ x: '100%', y: 328 }}
      className="np-sign-in-history-table"
      {...props}
    />
  );
};

export default SignInHistoryTable;
