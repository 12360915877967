import React from 'react';

import { TableWithHeader } from 'components';

import useMiningNotificationsTableColumns from './hooks/useMiningNotificationsTableColumns';

import './MiningNotificationsTable.styles.scss';

const MiningNotificationsTable = ({ onEdit, className = '', ...props }) => {
  const columns = useMiningNotificationsTableColumns({ onEdit });

  return (
    <TableWithHeader
      title="miningSettings.hashrateFluctuationNotification.table.title"
      columns={columns}
      rowKey={record => record.coin_type}
      pagination={{ position: [] }}
      scroll={{ x: '100%' }}
      className={`np-mining-notifications-table ${className}`}
      {...props}
    />
  );
};

export default MiningNotificationsTable;
