import React, { useCallback, useMemo } from 'react';
import { CloudUploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { Button, Typography } from 'antd';

import { Loading } from 'components';

import './FilesDropzone.styles.scss';

const { Text } = Typography;

const FilesDropzone = ({ onUpload = () => {}, onError = () => {}, multiple, loading, disabled, ...props }) => {
  const { t } = useTranslation();

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (fileRejections.length) {
        return onError(fileRejections);
      }

      if (acceptedFiles.length) {
        return onUpload(acceptedFiles);
      }
    },
    [onError, onUpload],
  );

  const { getRootProps, getInputProps, open, isFocused, isDragAccept, isDragReject } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple,
    onDrop,
    disabled,
    ...props,
  });

  const actionClass = useMemo(() => {
    if (isFocused) {
      return 'np-files-dropzone--focused';
    }

    if (isDragAccept) {
      return 'np-files-dropzone--drag-accept';
    }

    if (isDragReject) {
      return 'np-files-dropzone--drag-reject';
    }
  }, [isDragAccept, isDragReject, isFocused]);

  return (
    <div {...getRootProps()} className={`np-files-dropzone ${actionClass}`}>
      <Loading isLoading={loading}>
        <input {...getInputProps()} />

        <div className="np-files-dropzone__preview">
          <CloudUploadOutlined
            className={`np-files-dropzone__preview-icon ${disabled && 'np-files-dropzone__preview-icon--disabled'}`}
          />

          <div className="np-files-dropzone__preview-text-wrapper">
            <Text>{t('common.dropFiles')}</Text>

            <div>
              <Text>{t('common.or')}</Text>

              <Button type="link" onClick={open} disabled={disabled}>
                {t('form.button.browse')}
              </Button>
            </div>
          </div>
        </div>
      </Loading>

      {disabled && <div className="np-files-dropzone__overlay" />}
    </div>
  );
};

export default FilesDropzone;
