import React from 'react';
import { Menu } from 'antd';

import { Logo, SocialMediaButton } from 'components';

import useSidebarBodyHeight from './hooks/useSidebarBodyHeight';

import { MediaButtonColor, MediaTypes } from 'components/buttons/SocialMediaButton';

import './Sidebar.styles.scss';

const Sidebar = ({ active, logoUrl, className = '', menuProps = {}, menuFooter, ...props }) => {
  const mediaTypes = [MediaTypes.INSTAGRAM, MediaTypes.TELEGRAM, MediaTypes.TWITTER];

  const { defaultOpenKeys, items = [], ...otherMenuProps } = menuProps;

  const selectedKeys = Array.isArray(active) ? active : [active];
  const openKeys = Array.isArray(defaultOpenKeys) ? defaultOpenKeys : [defaultOpenKeys];

  const { headerRef, footerRef, menuFooterRef, bodyHeight } = useSidebarBodyHeight();

  const emptyBodyClass = items.length ? '' : 'np-sidebar__body--empty';
  const emptyMenuFooterClass = items.length ? '' : 'np-sidebar__menu-footer--empty';

  return (
    <div className={`np-sidebar ${className}`} {...props}>
      <div>
        <div ref={headerRef} className="np-sidebar__header">
          <Logo url={logoUrl} />
        </div>

        <div style={{ height: bodyHeight }} className={`np-sidebar__body ${emptyBodyClass}`}>
          <Menu
            mode="inline"
            selectedKeys={selectedKeys}
            defaultOpenKeys={openKeys}
            items={items}
            {...otherMenuProps}
          />
        </div>

        {menuFooter && (
          <div ref={menuFooterRef} className={`np-sidebar__menu-footer ${emptyMenuFooterClass}`}>
            {menuFooter}
          </div>
        )}
      </div>

      <div ref={footerRef} className="np-sidebar__footer">
        {mediaTypes.map(type => (
          <SocialMediaButton filled key={type} type={type} color={MediaButtonColor.GRAY} />
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
