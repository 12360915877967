import React from 'react';
import { Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import formatDate from 'utils/date/formatDate';
import formatBtcAmount from 'utils/coins/formatBtcAmount';

import { DATE_DOT_FORMAT_WITH_FULL_TIME } from 'constants/date';

import './BalanceTooltipContent.styles.scss';

const { Text } = Typography;

const BalanceTooltipContent = ({ balanceOnHold, paymentDue }) => {
  const { t } = useTranslation();

  return (
    <Space direction="vertical" className="np-balance-tooltip-content">
      <Text>{t('tooltip.balanceTooltip.onHold')}:</Text>

      <Text>{formatBtcAmount(balanceOnHold)} BTC</Text>

      {paymentDue && (
        <>
          <Text>{t('tooltip.balanceTooltip.paymentDue')}:</Text>

          <Text>{formatDate(paymentDue, { utc: false, format: DATE_DOT_FORMAT_WITH_FULL_TIME })}</Text>
        </>
      )}
    </Space>
  );
};

export default BalanceTooltipContent;
