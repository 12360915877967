import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Radio } from 'antd';

import { BlockWrapper, Chart, Loading } from 'components';

import useChartOptions from './hooks/useChartOptions';
import { useFilterOptions } from './hooks/useFilterOptions';

import { Granularity } from './constants/granularity';

import './HashrateChart.styles.scss';

const HashrateChart = ({ titleClass, title, column, onChangeColumn, requestCallback, ...props }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [granularity, setGranularity] = useState(column || Granularity.H5);

  const { t } = useTranslation();
  const { selectedAccountId } = useSelector(state => state.watcherAccounts);

  const filterOptions = useFilterOptions();
  const chartOptions = useChartOptions(data, { granularity });

  const getChartData = useCallback(async () => {
    setIsLoading(true);

    const result = await requestCallback({
      column: granularity,
    });

    const { data, success } = result || {};

    if (success && data?.type !== 'error') {
      setData(data);
    } else {
      setData(null);
    }

    setIsLoading(false);
  }, [granularity, requestCallback]);

  useEffect(() => {
    getChartData();
  }, [selectedAccountId, getChartData]);

  return (
    <BlockWrapper {...props}>
      <div className="np-hashrate-chart__header">
        <BlockWrapper.Title className={titleClass}>{title || t('hashrateChart.title')}</BlockWrapper.Title>
        <Radio.Group
          options={filterOptions}
          onChange={({ target: { value } }) => {
            setGranularity(value);

            if (onChangeColumn) {
              onChangeColumn(value);
            }
          }}
          value={granularity}
          size="small"
          optionType="button"
          className="np-hashrate-chart__switchers"
        />
      </div>

      <Loading isLoading={isLoading} isEmpty={!isLoading && !data}>
        {chartOptions && !isLoading ? <Chart option={chartOptions} /> : null}
      </Loading>
    </BlockWrapper>
  );
};

export default HashrateChart;
