import React from 'react';
import { Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { setLanguage } from 'store/reducers/lang';

import { LanguageMenuItems } from 'constants/languages';

import './LanguageSelect.styles.scss';

const LanguageSelect = () => {
  const dispatch = useDispatch();
  const { language } = useSelector(state => state.language);

  const languageLabel = LanguageMenuItems.find(e => e.key === language)?.label;

  const onLangChange = e => {
    dispatch(setLanguage(e.key));
  };

  const menuConfig = {
    items: LanguageMenuItems,
    selectable: true,
    selectedKeys: [language],
    onClick: onLangChange,
  };

  return (
    <Dropdown trigger={['click']} menu={menuConfig} className="np-language-select">
      <a onClick={e => e.preventDefault()}>
        <Space>
          <span>{languageLabel}</span>
          <DownOutlined className="np-language-select__icon" />
        </Space>
      </a>
    </Dropdown>
  );
};

export default LanguageSelect;
