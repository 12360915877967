import React from 'react';
import { useTranslation } from 'react-i18next';

import './InlineInfoBlock.styles.scss';

const InlineInfoBlock = ({ title = '', rows = [], noPadding, className = '' }) => {
  const { t } = useTranslation();

  const noPaddingClass = noPadding ? 'np-inline-info-block--no-padding' : '';

  return (
    <div className={`np-inline-info-block ${noPaddingClass} ${className}`}>
      {title && <div className="np-inline-info-block__header">{t(title)}</div>}

      <div className="np-inline-info-block__rows">
        {rows.map(row => {
          return (
            <React.Fragment key={row.title}>
              <div className="np-inline-info-block__rows-title">{row.title}</div>

              {row.noValue && <div />}

              {row.value}

              {!row.editable && <div />}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default InlineInfoBlock;
