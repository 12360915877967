import React from 'react';
import { Table as AntdTable } from 'antd';

import { NoData, Spin } from 'components';

import useTableSorter from './hooks/useTableSorter';
import replacePaginationElements from './utils/replacePaginationElements';

import { DEFAULT_PAGINATION } from './constants/pagination';

import './Table.styles.scss';

const Table = ({ loading, columns, pagination, className = '', scroll, stretch, onChange, ...props }) => {
  const stretchClass = stretch ? 'np-table--stretch' : '';

  const { columns: sortColumns, onChange: handleChange } = useTableSorter({ columns, onChange });

  return (
    <AntdTable
      onChange={handleChange}
      columns={sortColumns}
      ref={replacePaginationElements}
      className={`np-table ${stretchClass} ${className}`}
      loading={loading && { indicator: <Spin /> }}
      locale={{ emptyText: <NoData /> }}
      scroll={{ x: '100%', y: '100%', ...scroll }}
      pagination={
        pagination === false
          ? pagination
          : {
              ...DEFAULT_PAGINATION,
              ...pagination,
            }
      }
      {...props}
    />
  );
};

export default Table;
