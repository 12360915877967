import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';

import { FormButtons } from 'modules';

import useForm from 'hooks/forms/useForm';
import useValidation from 'hooks/forms/useValidation';
import useAutoFocus from 'hooks/elements/useAutoFocus';
import ymProtectInput from 'utils/yandex-metrika/ymProtectInput';

import { YM_DISABLE_SUBMIT_CLASS } from 'constants/yandexMetrika';

import './PaymentPasswordForm.styles.scss';

const PaymentPasswordForm = ({ defaultValues = {}, loading, onFinish = () => {} }) => {
  const inputRef = useRef();
  const rules = useValidation();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { submitDisabled } = useForm(form);

  const newPaymentPassword = Form.useWatch('newPaymentPassword', form);

  useAutoFocus(inputRef);

  return (
    <Form
      form={form}
      initialValues={defaultValues}
      onFinish={onFinish}
      requiredMark="optional"
      layout="vertical"
      className={`np-payment-password-form ${YM_DISABLE_SUBMIT_CLASS}`}
    >
      <Form.Item
        validateFirst
        name="newPaymentPassword"
        dependencies={['newPaymentPasswordConfirm']}
        label={t('form.label.newPaymentPassword')}
        rules={rules.paymentPassword}
      >
        <Input.Password autoFocus size="large" disabled={loading} ref={ref => ymProtectInput(ref, inputRef)} />
      </Form.Item>

      <Form.Item
        validateFirst
        name="newPaymentPasswordConfirm"
        dependencies={['newPaymentPassword']}
        label={t('form.label.newPaymentPasswordConfirm')}
        rules={rules.paymentPasswordWithMatch(newPaymentPassword)}
      >
        <Input.Password size="large" disabled={loading} ref={ymProtectInput} />
      </Form.Item>

      <FormButtons marginTop={12} submitDisabled={submitDisabled} loading={loading} submitLabel="form.button.next" />
    </Form>
  );
};

export default PaymentPasswordForm;
