import React, { useMemo } from 'react';
import { Button, Statistic } from 'antd';

import './TimerButton.styles.scss';

const { Countdown } = Statistic;

const TimerButton = ({
  children,
  ms = 0,
  timerMode = true,
  format = 'mm:ss',
  className = '',
  onChangeTimerMode = () => {},
  ...props
}) => {
  const timerValue = useMemo(() => {
    if (timerMode) {
      return Date.now() + ms;
    }
  }, [ms, timerMode]);

  const onChange = value => {
    if (value <= 0) {
      onChangeTimerMode(false);
    }
  };

  return (
    <div className={`np-timer-button ${className}`}>
      {!timerMode ? (
        <Button type="link" className="np-timer-button__button" {...props}>
          {children}
        </Button>
      ) : (
        <Countdown value={timerValue} format={format} onChange={onChange} className="np-timer-button__timer" />
      )}
    </div>
  );
};

export default TimerButton;
