import React, { useState } from 'react';
import { Checkbox, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { ListBlock } from 'components';

import { getCheckboxGroupValues, verifyCheckedAll, verifyCheckedIndeterminate } from './utils/checkboxGroup';

import './CheckboxList.styles.scss';

const { Text } = Typography;

const CheckboxList = ({
  value = [],
  options = [],
  loading,
  onChange,
  maxWidth,
  height,
  itemsLabel = 'common.items',
  ...props
}) => {
  const checkboxGroupValues = getCheckboxGroupValues(value);

  const isCheckedAll = verifyCheckedAll(checkboxGroupValues, options);
  const isCheckedIndeterminate = verifyCheckedIndeterminate(checkboxGroupValues, options);

  const [checkAll, setCheckAll] = useState(isCheckedAll);
  const [indeterminate, setIndeterminate] = useState(isCheckedIndeterminate);

  const { t } = useTranslation();

  const handleChange = list => {
    const indeterminate = verifyCheckedIndeterminate(list, options);
    const checkAll = verifyCheckedAll(list, options);

    setIndeterminate(indeterminate);
    setCheckAll(checkAll);
    onChange(list);
  };

  const onCheckAllChange = e => {
    const optionsValues = options.map(option => option?.value || option);

    setIndeterminate(false);
    setCheckAll(e.target.checked);
    onChange(e.target.checked ? optionsValues : []);
  };

  return (
    <ListBlock
      maxWidth={maxWidth}
      height={height}
      loading={loading}
      empty={!options.length}
      error={props['aria-invalid']}
      header={
        <>
          <div>
            {!!options.length && (
              <Checkbox indeterminate={indeterminate} checked={checkAll} onChange={onCheckAllChange} />
            )}
          </div>

          <Text>
            {value.length}/{options.length} {t(itemsLabel)}
          </Text>
        </>
      }
    >
      <Checkbox.Group value={checkboxGroupValues} options={options} onChange={handleChange} {...props} />
    </ListBlock>
  );
};

export default CheckboxList;
