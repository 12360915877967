import React from 'react';
import { Dropdown, Space, Typography } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';

import { Spin } from 'components';

import './UserMenu.styles.scss';

const { Text } = Typography;

const UserMenu = ({ name, footer, options = [], loading, onOptionClick = () => {}, menuClassName = '', ...props }) => {
  return (
    <Dropdown
      menu={{
        items: options,
        onClick: onOptionClick,
        className: menuClassName,
        triggerSubMenuAction: 'click',
      }}
      dropdownRender={menus => (
        <div className="np-user-menu__dropdown">
          <div className="np-user-menu__dropdown-menu">{loading ? <Spin /> : menus}</div>
          {footer && <div className="np-user-menu__dropdown-footer">{footer}</div>}
        </div>
      )}
      trigger={['click']}
      {...props}
    >
      <Space className="np-user-menu__title">
        <Text>{name ? name : <UserOutlined />}</Text>
        <DownOutlined />
      </Space>
    </Dropdown>
  );
};

export default UserMenu;
