import React from 'react';
import { Result } from 'antd';
import { useTranslation } from 'react-i18next';

const ErrorMessage = ({ title = 'resetProcess.error.title', subTitle = 'resetProcess.error.subTitle' }) => {
  const { t } = useTranslation();

  return <Result status="error" title={t(title)} subTitle={t(subTitle)} />;
};

export default ErrorMessage;
