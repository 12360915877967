import React from 'react';
import { Button } from 'antd';

import './VipButton.styles.scss';

const VipButton = ({ children, size = 'large', className = '', ...props }) => {
  return (
    <Button shape="round" type="primary" size={size} block className={`np-vip-button ${className}`} {...props}>
      {children}
    </Button>
  );
};

export default VipButton;
