import React, { useState, useRef } from 'react';
import { Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { Form } from 'components';
import { FormButtons } from 'modules';

import useForm from 'hooks/forms/useForm';
import useUserData from 'hooks/user/useUserData';
import useValidation from 'hooks/forms/useValidation';
import useAutoFocus from 'hooks/elements/useAutoFocus';
import useNotifications from 'hooks/common/useNotifications';
import { useDisable2FaMutation } from '../../hooks/useDisable2FaMutation';

import ymProtectInput from 'utils/yandex-metrika/ymProtectInput';

import { publicUrls } from 'constants/urls';

import './Disable2FaForm.styles.scss';

const Disable2FaForm = ({ onFinish = () => {} }) => {
  const inputRef = useRef();
  const rules = useValidation();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { notificationApi } = useNotifications();

  const { reloadUser } = useUserData();
  const { loading, refetch: disableUser2Fa } = useDisable2FaMutation();

  const { submitDisabled } = useForm(form);

  useAutoFocus(inputRef);

  const [recoveryMode, setRecoveryMode] = useState(false);

  const handleFinish = () => {
    form.resetFields();
    reloadUser();
    setRecoveryMode(false);
    onFinish();
  };

  const handleSubmit = async ({ totp, otp }) => {
    const { success, errorMessage } = await disableUser2Fa({ totp, otp });

    if (success) {
      handleFinish();
    } else {
      notificationApi.error({ message: t(errorMessage) });
    }
  };

  return (
    <>
      <Form
        className="np-disable-2fa-form__body"
        layout="vertical"
        form={form}
        name="disable-2fa"
        onFinish={handleSubmit}
        scrollToFirstError
        requiredMark="optional"
      >
        {!recoveryMode ? (
          <Form.Item
            name="totp"
            validateFirst
            rules={rules.ga}
            label={t('form.label.gaCode')}
            className="np-disable-2fa-form__totp"
          >
            <Input.Password size="large" autoFocus disabled={loading} ref={ref => ymProtectInput(ref, inputRef)} />
          </Form.Item>
        ) : (
          <Form.Item
            name="otp"
            validateFirst
            rules={rules.ga}
            label={t('form.label.recoveryCode')}
            className="np-disable-2fa-form__totp"
          >
            <Input.Password size="large" autoFocus disabled={loading} ref={ymProtectInput} />
          </Form.Item>
        )}

        {!recoveryMode ? (
          <Button type="link" onClick={() => setRecoveryMode(true)} className="np-disable-2fa-form__link">
            {t('common.forgotLinks.gaNoAccess')}
          </Button>
        ) : (
          <NavLink to={publicUrls.resetGa} className="np-disable-2fa-form__link">
            <Button type="link" className="np-disable-2fa-form__link">
              {t('common.forgotLinks.recoveryCodes')}
            </Button>
          </NavLink>
        )}

        <FormButtons submitDisabled={submitDisabled} loading={loading} submitLabel="form.button.submit" />
      </Form>
    </>
  );
};

export default Disable2FaForm;
