import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { EditOutlined } from '@ant-design/icons';

import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';

import './EditButton.styles.scss';

const EditButton = ({ ...props }) => {
  const { t } = useTranslation();
  const { isMobileView } = useWindowResponsiveness(700);

  return (
    <Button size="middle" icon={<EditOutlined />} className="np-editable-block-value__button" {...props}>
      {!isMobileView && t('form.button.edit')}
    </Button>
  );
};

export default EditButton;
