export const PaymentAmount = {
  ONE: 1,
  ONE_TENTH: 0.1,
  ONE_HUNDREDTH: 0.01,
  ONE_THOUSANDTH: 0.001,
};

export const MIN_PAYMENT_AMOUNT = 0.001;
export const PAYMENT_TIME = '2:00-10:00';

export const PaymentTransfer = {
  INTER_USER_TRANSFER: 'inter-user-transfer',
  NORMAL_TRANSFER: 'normal-transfer',
};
