import { apiUrls } from 'constants/urls';
import asyncErrorHandler from 'utils/requests/errorHandler';
import request from './request.service';

export const getAccountAvgHashrate = async accountId => {
  const callback = async () => {
    return await request(apiUrls.statistics.hashrateStatsByAcc(accountId));
  };

  return await asyncErrorHandler(callback);
};

export const getAccountProfitChart = async accountId => {
  const callback = async () => {
    return await request(apiUrls.charts.profitChart(accountId));
  };

  return await asyncErrorHandler(callback);
};

export const getProfitAndHashrate = async accountId => {
  const callback = async () => {
    return await request(apiUrls.statistics.profitTable(accountId));
  };

  return await asyncErrorHandler(callback);
};

export const getAccountsSummary = async () => {
  const callback = async () => {
    return await request(apiUrls.accounts.accountSummary);
  };

  return await asyncErrorHandler(callback);
};
