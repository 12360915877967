import React from 'react';
import { Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { ListBlock } from 'components';

import useNotifications from 'hooks/common/useNotifications';

import './CompletedStep.styles.scss';

const { Text } = Typography;

const CompletedStep = ({ codes = [] }) => {
  const { t } = useTranslation();
  const { notificationApi } = useNotifications();

  const emptyData = !codes || !codes.length;

  const copyResetCodes = async () => {
    try {
      await navigator.clipboard.writeText(codes.join(' '));
      notificationApi.success({ message: t('accountSettings.accountSecurity.successClipBoard') });
    } catch (e) {
      notificationApi.warning({ message: t('accountSettings.accountSecurity.errorClipBoard') });
    }
  };

  return (
    <div className="np-enable-2fa-comleted-step">
      <Text className="np-enable-2fa-comleted-step__title">
        {t('accountSettings.accountSecurity.form.recoveryCodes')}
      </Text>

      <ListBlock empty={emptyData} className="np-enable-2fa-comleted-step__list-block">
        <div className="np-enable-2fa-comleted-step__codes">
          {codes.map((code, index) => (
            <Text key={index}>{code}</Text>
          ))}
        </div>
      </ListBlock>

      <Button block size="large" disabled={emptyData} onClick={copyResetCodes} type="primary">
        {t('accountSettings.accountSecurity.clipBoardBtn')}
      </Button>
    </div>
  );
};

export default CompletedStep;
