import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { LogoutIcon } from 'assets/images';

import { publicUrls } from 'constants/urls';

import './UserMenuFooter.styles.scss';

const UserMenuFooter = () => {
  const { t } = useTranslation();

  return (
    <div className="np-user-menu-footer">
      <Button type="link" href={publicUrls.logout} icon={<LogoutIcon />} className="np-user-menu-footer__logout">
        {t('menu.userProfile.logOut')}
      </Button>
    </div>
  );
};

export default UserMenuFooter;
