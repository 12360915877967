import React from 'react';

import { Steps } from 'antd';

import BaseModal from '../BaseModal/BaseModal';

import './StagedModal.styles.scss';

const StagedModal = ({
  children,
  currentStep,
  steps = [],
  stepsProps,
  onChangeStep,
  beforeStepsComponent,
  ...props
}) => {
  const noSteps = !steps || !steps.length;
  const currentStepNumber = steps.findIndex(step => step.key === currentStep);

  const handleChangeStep = stepNumber => {
    const stepKey = steps[stepNumber]?.key;

    onChangeStep(stepKey);
  };

  return (
    <BaseModal {...props}>
      {beforeStepsComponent}

      {!noSteps && (
        <Steps
          items={steps}
          labelPlacement="vertical"
          current={currentStepNumber}
          onChange={onChangeStep && handleChangeStep}
          className="np-staged-modal__steps"
          {...stepsProps}
        />
      )}

      {children}
    </BaseModal>
  );
};

export default StagedModal;
