import React from 'react';
import { Button } from 'antd';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { publicUrls } from 'constants/urls';

import './ForgotLinks.styles.scss';

const ForgotLinks = ({ includePaymentPassword, includeGa, className = '', align = 'center' }) => {
  const { t } = useTranslation();

  const showLinks = includePaymentPassword || includeGa;

  return showLinks ? (
    <div className={`np-forgot-links ${className}`} style={{ justifyContent: align }}>
      {includePaymentPassword && (
        <NavLink to={publicUrls.profile.resetPaymentPassword}>
          <Button type="link">{t('common.forgotLinks.paymentPassword')}</Button>
        </NavLink>
      )}

      {includeGa && (
        <NavLink to={publicUrls.resetGa}>
          <Button type="link">{t('common.forgotLinks.ga')}</Button>
        </NavLink>
      )}
    </div>
  ) : null;
};

export default ForgotLinks;
