import React from 'react';

import { TableWithHeader } from 'components';

import useMySharingTableColumns from './hooks/useWatcherUrlsTableColumns';

import './WatcherUrlsTable.styles.scss';

const WatcherUrlsTable = ({ className = '', onDelete, ...props }) => {
  const columns = useMySharingTableColumns({ onDelete });

  return (
    <TableWithHeader
      stretch
      columns={columns}
      title="watcher.mySharing.title"
      description="watcher.mySharing.desc"
      className={`np-watcher-urls-table ${className}`}
      {...props}
    />
  );
};

export default WatcherUrlsTable;
