import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckCircleFilled } from '@ant-design/icons';

import useWorkersStatus from './hooks/useWorkersStatus';
import getComputingSpeedUnit from 'utils/power/getComputingSpeedUnit';

import './SubAccountMenuItem.styles.scss';

const { Text } = Typography;

const SubAccountMenuItem = ({ name, hr, hrPower, workers, selected, isMain }) => {
  const { t } = useTranslation();

  const selectedClass = selected ? 'selected' : '';
  const accountType = isMain ? t('common.mainLowerCase') : t('common.subAccountLowerCase');

  const workerStatuses = useWorkersStatus(workers);

  return (
    <div className={`np-user-menu-sub-account ${selectedClass}`}>
      <div className="np-user-menu-sub-account__header">
        <Text className="np-user-menu-sub-account__header-title">
          {name} ({accountType})
        </Text>

        {selected && <CheckCircleFilled className="np-user-menu-sub-account__header-icon" />}
      </div>

      <div className="np-user-menu-sub-account__hashrate">
        <div className="np-user-menu-sub-account__hashrate-circle" />

        <Text className="np-user-menu-sub-account__hashrate-text">{getComputingSpeedUnit(hr, hrPower)}</Text>
      </div>

      <div className="np-user-menu-sub-account__workers">
        <Text className="np-user-menu-sub-account__workers-title">
          {t('menu.userProfile.subAccountsInfo.workerStatus')}
        </Text>

        <div className="np-user-menu-sub-account__workers-body">
          {workerStatuses.map(worker => (
            <div key={worker.label} className="np-user-menu-sub-account__workers-body-item">
              {worker.icon}

              <Text>
                {worker.label}: {worker.value}
              </Text>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubAccountMenuItem;
