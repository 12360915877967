import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';

import { Form } from 'components';
import { FormButtons } from 'modules';

import useForm from 'hooks/forms/useForm';
import useValidation from 'hooks/forms/useValidation';

import './ConfirmationForm.styles.scss';

const ConfirmationForm = ({ noticements = [], onFinish = () => {} }) => {
  const rules = useValidation();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { submitDisabled } = useForm(form);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      requiredMark="optional"
      validateTrigger="onSubmit"
      name="reset-pay-pass-confirm"
      className="np-reset-process-confirmation__form"
    >
      <Form.Item name="confirmations" rules={[rules.requiredMinCount(noticements.length, t('validation.confirmAll'))]}>
        <Checkbox.Group>
          {noticements.map(option => (
            <Checkbox key={option.value} value={option.value}>
              {t(option.label)}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Form.Item>

      <FormButtons marginTop={12} submitDisabled={submitDisabled} />
    </Form>
  );
};

export default ConfirmationForm;
