import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';

import { FormButtons } from 'modules';
import { Form, FormHiddenInputs } from 'components';

import useForm from 'hooks/forms/useForm';
import useValidation from 'hooks/forms/useValidation';
import useAutoFocus from 'hooks/elements/useAutoFocus';
import ymProtectInput from 'utils/yandex-metrika/ymProtectInput';
import useCheckInputSpecialSymbols from 'hooks/forms/useCheckInputSpecialSymbols';

import { YM_DISABLE_SUBMIT_CLASS } from 'constants/yandexMetrika';

import './ExportForm.styles.scss';

const ExportForm = ({ loading, onFinish = async () => {} }) => {
  const inputRef = useRef();
  const rules = useValidation();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { submitDisabled } = useForm(form);

  useAutoFocus(inputRef);

  useCheckInputSpecialSymbols('email', form);

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      requiredMark="optional"
      className={`np-export-form ${YM_DISABLE_SUBMIT_CLASS}`}
    >
      <FormHiddenInputs />

      <Form.Item validateFirst name="email" label={t('form.label.email')} rules={[rules.required, rules.email]}>
        <Input autoFocus size="large" disabled={loading} ref={ref => ymProtectInput(ref, inputRef)} />
      </Form.Item>

      <FormButtons marginTop={0} submitDisabled={submitDisabled} loading={loading} submitLabel="form.button.submit" />
    </Form>
  );
};

export default ExportForm;
