import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Chart, Loading, BlockWrapper } from 'components';

import useChartOptions from './hooks/useChartOptions';

import './ProfitChart.styles.scss';

const ProfitChart = ({ requestCallback, ...props }) => {
  const { t } = useTranslation();

  const [data, setData] = useState(true);
  const [loading, setLoading] = useState(true);

  const options = useChartOptions(data);

  const noData = !data || !data?.series?.length || !data?.dates?.length;

  useEffect(() => {
    const getChartData = async () => {
      setLoading(true);

      const { data, success } = await requestCallback();

      if (success) {
        setData(data);
      } else {
        setData(null);
      }

      setLoading(false);
    };

    getChartData();
  }, [requestCallback]);

  return (
    <BlockWrapper {...props}>
      <BlockWrapper.Title className="profit-chart__title">{t('profitChart.title')}</BlockWrapper.Title>

      <Loading isLoading={loading} isEmpty={!loading && noData}>
        <Chart option={options} />
      </Loading>
    </BlockWrapper>
  );
};

export default ProfitChart;
