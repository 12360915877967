import React from 'react';

import Header from './components/Header';
import { InternalNotifications } from 'modules';
import { PageLoader, BackgroundLayout, Footer } from 'components';

import useRedirectNewUser from './hooks/useRedirectNewUser';

import { LayoutBackgroundType } from './constants/background';

const BaseLayout = ({
  children,
  disableStickHeader,
  showFooter,
  showLogo,
  backgroundType = LayoutBackgroundType.SOLID,
}) => {
  const { loading } = useRedirectNewUser();

  return loading ? (
    <PageLoader />
  ) : (
    <BackgroundLayout className="np-container" backgroundType={backgroundType}>
      <Header sticky={!disableStickHeader} showLogo={showLogo} />

      <div className="np-container__content">
        <InternalNotifications />

        {children}
      </div>

      {showFooter && <Footer />}
    </BackgroundLayout>
  );
};

export default BaseLayout;
