import React from 'react';
import { useTranslation } from 'react-i18next';

import { Loading } from 'components';
import EditButton from './components/EditButton';

import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';

import './EditableBlockValue.styles.scss';

const EditableBlockValue = ({
  value,
  loading,
  disabled,
  rightActionsComponent,
  wordBreak,
  onEditButtonClick = () => {},
}) => {
  const { t } = useTranslation();
  const { isMobileView } = useWindowResponsiveness(700);

  const fontSize = isMobileView ? 14 : 16;

  return (
    <>
      <Loading isLoading={loading} className="np-editable-block-value__loading" inline noMargin>
        <div className="np-editable-block-value__actions-label">
          <span style={{ wordBreak, fontSize }}>{value || t('common.notSet')}</span>
        </div>
      </Loading>

      <div className="np-editable-block-value__actions">
        {<EditButton onClick={onEditButtonClick} disabled={disabled} />}
        {rightActionsComponent}
      </div>
    </>
  );
};

export default EditableBlockValue;
