import React from 'react';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';

import { ActionIconButton } from 'components';
import { CopyIcon, PermissionIcon } from 'assets/images';
import PermissionsPopover from '../components/PermissionsPopover';

import useNotifications from 'hooks/common/useNotifications';
import generateWatcherLink from 'utils/watcher/generateWatcherLink';

import { ActionIconColors } from 'components/buttons/ActionIconButton';

const useWatcherUrlsTableColumns = (options = {}) => {
  const { onDelete } = options;
  const { notificationApi } = useNotifications();

  const { t } = useTranslation();

  const copyWatcherLink = async accessKey => {
    try {
      const link = generateWatcherLink({ accessKey, fullPath: true });

      await navigator.clipboard.writeText(link);
      notificationApi.success({ message: t('watcher.watcherLink.successClipBoard') });
    } catch (e) {
      notificationApi.warning({ message: t('watcher.watcherLink.errorClipBoard') });
    }
  };

  const renderActions = record => {
    return (
      <div className="np-watcher-urls-table__actions">
        <PermissionsPopover permissions={record.access_levels}>
          <ActionIconButton icon={<PermissionIcon />} />
        </PermissionsPopover>

        <Tooltip title={t('common.table.actions.copy')}>
          <ActionIconButton icon={<CopyIcon />} onClick={() => copyWatcherLink(record.key)} />
        </Tooltip>

        {onDelete && (
          <Tooltip title={t('common.table.actions.delete')}>
            <ActionIconButton
              icon={<DeleteOutlined />}
              color={ActionIconColors.RED}
              onClick={() => onDelete(record.link_id)}
            />
          </Tooltip>
        )}
      </div>
    );
  };

  return [
    {
      title: t('watcher.mySharing.table.miningAccount'),
      dataIndex: 'link_account',
      key: 'link_account',
      align: 'center',
      width: '150px',
      render: value => value || 'Main',
    },
    {
      title: t('watcher.mySharing.table.url'),
      dataIndex: 'key',
      key: 'key',
      align: 'center',
      width: '220px',
      ellipsis: true,
      render: accessKey => generateWatcherLink({ accessKey, fullPath: true }),
    },

    {
      title: t('watcher.mySharing.table.Remark'),
      dataIndex: 'link_name',
      key: 'link_name',
      align: 'center',
      width: '100px',
    },
    {
      key: 'actions',
      align: 'center',
      width: '80px',
      fixed: 'right',
      render: (_, record) => renderActions(record),
    },
  ];
};

export default useWatcherUrlsTableColumns;
