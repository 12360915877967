import React from 'react';
import { useTranslation } from 'react-i18next';

import { BlockWrapper, Table } from 'components';

import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';

import './TableWithHeader.styles.scss';

const TableWithHeader = ({
  title = '',
  description = '',
  actionsComponent = null,
  rightHeaderComponent = null,
  leftHeaderComponent = null,
  footer = null,
  titleSize = 'large',
  stretch,
  className = '',
  ...props
}) => {
  const { t } = useTranslation();
  const { isMobileView } = useWindowResponsiveness(700);

  return (
    <BlockWrapper stretch noPadding className={`np-table-with-header ${className}`}>
      <BlockWrapper.Header className="np-table-with-header__header">
        <div className="np-table-with-header__header-info">
          <div className="np-table-with-header__header-title-wrapper">
            <BlockWrapper.Title size={titleSize}>{t(title)}</BlockWrapper.Title>
            {isMobileView && rightHeaderComponent}
          </div>

          {description && (
            <BlockWrapper.Description className="np-table-with-header__header-desc">
              {t(description)}
            </BlockWrapper.Description>
          )}

          {leftHeaderComponent}
        </div>

        {!isMobileView && rightHeaderComponent}
      </BlockWrapper.Header>

      {actionsComponent && <div className="np-table-with-header__actions">{actionsComponent}</div>}

      <Table stretch={stretch} {...props} />

      {footer}
    </BlockWrapper>
  );
};

export default TableWithHeader;
