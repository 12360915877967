import React from 'react';
import { AutoComplete, Form, Input } from 'antd';

import ymProtectInput from 'utils/yandex-metrika/ymProtectInput';

import './FormHiddenInputs.styles.scss';

// Hack for chrome autofill
const FormHiddenInputs = () => (
  <div className="np-form-hidden-inputs">
    <Form.Item noStyle>
      <Input ref={ymProtectInput} autoComplete="email" tabIndex="-1" />
    </Form.Item>

    <Form.Item noStyle>
      <AutoComplete autoComplete="username" tabIndex="-1">
        <Input ref={ymProtectInput} />
      </AutoComplete>
    </Form.Item>

    <Form.Item noStyle>
      <Input.Password ref={ymProtectInput} autoComplete="password" tabIndex="-1" />
    </Form.Item>
  </div>
);

export default FormHiddenInputs;
