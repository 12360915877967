import React, { useState, useEffect, useMemo } from 'react';
import { Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { BellOutlined } from '@ant-design/icons';

import { BaseModal, Loading } from 'components';

import useUserData from 'hooks/user/useUserData';

import { ModalType, SecurityNoticeParams } from '../../constants/types';

import './NoticeModal.styles.scss';

const { Text } = Typography;

const SecurityNoticeModal = ({
  open,
  loading,
  types = [],
  showSkip,
  onClose = () => {},
  onReject = () => {},
  onSkip = () => {},
  onChangeType = () => {},
}) => {
  const { t } = useTranslation();
  const { user } = useUserData();

  const [type, setType] = useState(ModalType.NOTICEMENT);

  const showTwoTwoFa = !user['2fa'] && types.includes(SecurityNoticeParams.TWO_FA);
  const showPayPassword = !user.has_pay_password && types.includes(SecurityNoticeParams.PAYMENT_PASSWORD);

  const message = useMemo(() => {
    if (showPayPassword && showTwoTwoFa) {
      return t('modal.securityNotice.message.bindPayPasswordOrGa');
    }

    if (showPayPassword) {
      return t('modal.securityNotice.message.bindPayPassword');
    }

    if (showTwoTwoFa) {
      return t('modal.securityNotice.message.bindGa');
    }

    return '';
  }, [showTwoTwoFa, showPayPassword, t]);

  const afterOpenChange = open => {
    if (!open) {
      onChangeType(type);
    }

    setType(ModalType.NOTICEMENT);
  };

  useEffect(() => {
    if (!showPayPassword && !showTwoTwoFa) {
      onClose();
    }
  }, [showPayPassword, showTwoTwoFa, onClose]);

  return (
    <BaseModal
      width={400}
      icon={<BellOutlined />}
      title={t('modal.securityNotice.title')}
      open={open && type === ModalType.NOTICEMENT}
      onClose={onReject}
      afterOpenChange={afterOpenChange}
      className="np-notice-modal"
    >
      <Loading isLoading={loading}>
        <Text>{message}</Text>

        <div className="np-notice-modal__buttons">
          {showPayPassword && (
            <Button block type="primary" size="large" onClick={() => setType(ModalType.PAYMENT_PASSWORD)}>
              {t('modal.securityNotice.button.bindPayPassword')}
            </Button>
          )}

          {showTwoTwoFa && (
            <Button block type="primary" size="large" onClick={() => setType(ModalType.TWO_FA)}>
              {t('modal.securityNotice.button.bindGa')}
            </Button>
          )}

          {showSkip && (
            <Button block type="default" size="large" onClick={onSkip}>
              {t('form.button.skip')}
            </Button>
          )}
        </div>
      </Loading>
    </BaseModal>
  );
};

export default SecurityNoticeModal;
