import React from 'react';

import Header from './components/Header';
import { InternalNotifications } from 'modules';
import { BackgroundLayout } from 'components';

import { InternalNotificationsTypes } from 'constants/internalNotifications';

const WatcherLayout = ({ children }) => {
  return (
    <BackgroundLayout className="np-container">
      <Header />

      <div className="np-container__content">
        <InternalNotifications disabledTypes={[InternalNotificationsTypes.USER]} />

        {children}
      </div>
    </BackgroundLayout>
  );
};

export default WatcherLayout;
