import React from 'react';

import './MainContainer.styles.scss';

const MainContainer = ({ children, className, maxWidth, ...props }) => (
  <div style={{ maxWidth }} className={`np-main-container ${className ? className : ''}`} {...props}>
    {children}
  </div>
);

export default MainContainer;
