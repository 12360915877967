import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import './FilterSelect.styles.scss';
import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';

const FilterSelect = ({ size, width, style, placeholder, className = '', ...props }) => {
  const withAllOptionClass = !placeholder ? 'np-filter-select--with-all' : '';

  const { t } = useTranslation();
  const { isMobileView } = useWindowResponsiveness(700);

  return (
    <Select
      allowClear
      size={size ? size : isMobileView ? 'middle' : 'large'}
      style={{ width, ...style }}
      placeholder={t(placeholder || 'common.all')}
      className={`np-filter-select bordered ${withAllOptionClass} ${className}`}
      {...props}
    />
  );
};

export default FilterSelect;
